import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/vercel/path0/node_modules/@vercel/analytics/dist/react/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/holy-loader/dist/index.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"variable\":\"--font-brik\",\"display\":\"swap\",\"src\":[{\"path\":\"./../public/fonts/brik-regular.woff2\",\"weight\":\"400\",\"style\":\"normal\"}]}],\"variableName\":\"brik\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"variable\":\"--font-rogan\",\"display\":\"swap\",\"src\":[{\"path\":\"./../public/fonts/rogan-regular.woff2\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"./../public/fonts/rogan-bold.woff2\",\"weight\":\"700\",\"style\":\"normal\"}]}],\"variableName\":\"rogan\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/packages/nextjs-app/app/top-level-providers.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/nextjs-app/styles/globals.css");
